import React from 'react';
import CampaignSidebar  from '../pages/Campaigns/components/CampaignSidebar';
import JourneySidebar from '../pages/Journeys/components/JourneySidebar';
import JourneyIssuesSidebar from '../pages/Journeys/components/JourneyIssuesSidebar';
import JourneyReportSidebar from '../pages/Journeys/components/JourneyReportSidebar';
import { DeliveryReportSidebar } from 'pages/admin/traffic-shape/components';

import CreateJourney from '../pages/Journeys/JourneyDraftEdit';
import DropoutSidebar from '../pages/Journeys/components/Sidebar/DropoutSidebar';
import TriggerSidebar from '../pages/Journeys/components/Sidebar/TriggerSidebar';

import { useParams, withRouter } from 'react-router';
// import SingleCustomerPage from 'pages/Audiences/SingleCustomerPage/SingleCustomerPage';
import SendTestSidebar from '../pages/Campaigns/components/SendTestSidebar';
import { LibrarySidebar } from '../pages/Library/components';
import { StatisticsSidebar, CreateCampaign } from '../pages/Campaigns/components';
import  useLocalStore  from '../useLocalStore';
import Profile from '../pages/Audiences/Profile';
import SidebarMergeExit from '../pages/Journeys/components/Sidebar/SidebarMergeExit';


const Sidebar = ({ hideSidebar }) => {
    const [getSidebar, resetSidebar] = useLocalStore((state) => [state.getSidebar, state.resetSidebar]);

    const sidebarPayload = getSidebar();
    const { id } = useParams();

    console.log('setSidebarPayload', sidebarPayload);

    // TODO: these should be rendered conditionally - otheriwse its rendering every single one, just not
    //  displaying them
    const sidebarLookupMap = {
        // Journeys
        'Journeys':  <JourneySidebar />,
        'JourneyTrigger':  <TriggerSidebar flowId={id} hideSidebar={hideSidebar} />,
        'JourneyDropout': <DropoutSidebar isOpen={sidebarPayload.open} hideSidebar={hideSidebar} />,
        'JourneyReport': <JourneyReportSidebar status={sidebarPayload.params ?? false } />,
        'JourneyIssues': <JourneyIssuesSidebar issues={sidebarPayload.params ?? false } />,
        // 'JourneyMergeExit' : <SidebarMergeExit flowStepId={sidebarPayload.params.flowStepId} flowId={sidebarPayload.params.flowId} />,
        'JourneyMergeExit' : <SidebarMergeExit {...sidebarPayload.params}  />,

        // MISC
        'DeliveryReport': <DeliveryReportSidebar />,
        'Library': <LibrarySidebar template={sidebarPayload.params ?? false } />,

        // messages / campaigns
        'Campaigns': <CampaignSidebar type={sidebarPayload.params?.type ?? false} step={sidebarPayload.params ?? false} />,
        'CreateCampaign': <CreateCampaign step={sidebarPayload.params ?? false} />,
        'SendTest': <SendTestSidebar message={sidebarPayload.params ?? false} />,

        // profiles
        'Customers': <Profile profileId={sidebarPayload?.props?.profileId} />

    };


    const getSidebarComponentFromPayload = (name) => {
        try {
            return sidebarLookupMap[name];
        } catch (e) {
            console.log('Could not find sidebar', name );
            resetSidebar();
        }
    };

    return (
        <>
            {getSidebarComponentFromPayload(sidebarPayload.name )}
        </>
    );
};

export default withRouter(Sidebar);
