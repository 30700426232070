import React, { useState, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { Query, Builder, Utils as RaqbUtils } from 'react-awesome-query-builder';
import { getElementalFilterDataFromRaqbData } from 'components/QueryBuilder/lib/getElementalFilterDataFromRaqbData';

import 'react-awesome-query-builder/lib/css/styles.css';
// import {emptyInitValue} from 'util/constants';

import {useMutation, useQuery} from '@tanstack/react-query';
import {apiV1OrgUri} from 'constants/apiRoutes';
import * as http from '../../util/elemental_axios_client';

import QueryBuilderConfig from 'components/QueryBuilder/QueryBuilderConfig';
import getQueryBuilderConfig from 'components/QueryBuilder/getQueryBuilderConfig';

// const emptyRaqbImmutableTree = {
//     'type': 'group',
//     'id': '9a99988a-0123-4456-b89a-b1607f326fd8',
//     'children1': {
//         '989aa8a9-cdef-4012-b456-71815e439a6a': {
//             'type': 'rule',
//             'properties': {
//                 'field': null,
//                 'operator': null,
//                 'value': [],
//                 'valueSrc': []
//             }
//         }
//     },
//     'properties': {
//         'conjunction': 'AND',
//         'not': false
//     }
// };

const emptyRaqbImmutableTree  = { id: RaqbUtils.uuid(), type: 'group' };


const QueryBuilderWConfig = ( { initialElementalFilterData, initialRaqbJsonTree, onChange, queryBuilderConfig, onValid } ) => {


    // const getValidOrDefaultImmutableTreeFromJsonTree = (jsonTree) => {
    //     const validOrDefaultTree = ;
    //     return RaqbUtils.checkTree(RaqbUtils.loadTree(initialRaqbJsonTree ? initialRaqbJsonTree : emptyRaqbImmutableTree), queryBuilderConfig);
    // };


    // // the tree passed to the query builder itself
    const [raqbImmutableTree, setRaqbImmutableTree] = useState(RaqbUtils.checkTree(RaqbUtils.loadTree(initialRaqbJsonTree ? initialRaqbJsonTree : emptyRaqbImmutableTree), queryBuilderConfig) );

    // the tree in json format, can be passed to the backend and also loaded
    const [raqbJsonTree, setRaqbJsonTree] = useState(initialRaqbJsonTree);
    const [elementalFilterData, setElementalFilterData] = useState(initialElementalFilterData);

    // tracks if the query builder has setup a valid query or not
    const [queryIsValid, setQueryIsValid] = useState(true);
    const [queryCount, setQueryCount] = useState(0);
    const [querySql, setQuerySql] = useState('');



    const filterCountMutation = useMutation({
        mutationFn: async (filter_data) => {
            return await http.post(`${apiV1OrgUri}/audiences/filter/count`,{ filter_data : filter_data });
        },
        onSuccess: (data) => {
            console.log('filterCountMutation success data', data);
            setQueryCount(data.count);
            setQuerySql(data.sql);
        }
    });

    const filterValidateMutation = useMutation({
        mutationFn: async (filter_data) => {
            return await http.post(`${apiV1OrgUri}/audiences/filter/validate`,{ filter_data : filter_data });
        },
        onSuccess: (data) => {
            console.log('filterValidateMutation success data', data);
            setQueryIsValid(data.is_valid);
        }
    });

    // const runProfilesMutation= useMutation({
    //     mutationFn: async (filter_data) => {
    //         return await setFilteredCustomers({filter_data:filter_data});
    //     },
    //     onSuccess: (data) => {
    //         setFilteredData(data);
    //     }
    // });

    const renderBuilder = useCallback((props) => {
        return(
            <div className='query-builder-container'>
                <div className='query-builder'>
                    <Builder {...props} />
                </div>
            </div>
        );
    }, []);

    const onChangeRaqb = useCallback((immutableTree, config) => {
        console.log('','');
        console.log('onChangeRaqb START');

        // From the docs ->  Tip: for better performance you can apply `throttle` - see `examples/demo`
        // The docs ( https://github.com/ukrbublik/react-awesome-query-builder#minimal-typescript-example-with-function-component )
        // have the onChange setting immutableTree into the state like the code below:
        //   const onChange = useCallback((immutableTree: ImmutableTree, config: Config) => {
        //     // Tip: for better performance you can apply `throttle` - see `examples/demo`
        //     setState(prevState => ({ ...prevState, tree: immutableTree, config: config }));
        //
        //     const jsonTree = QbUtils.getTree(immutableTree);
        //     console.log(jsonTree);
        //     // `jsonTree` can be saved to backend, and later loaded to `queryValue`
        //   }, []);
        //
        // but in our case
        // its resetting the tree to empty when selecting conditions that use the CustomFilterComponent, need to further
        // investigate
        // setRaqbImmutableTree(immutableTree);
        console.log('onChangeRaqb: immutableTree', immutableTree);

        const raqbJsonTree = RaqbUtils.getTree(immutableTree);
        console.log('onChangeRaqb: raqbJsonTree', raqbJsonTree);
        setRaqbJsonTree(raqbJsonTree);

        let filterData = getElementalFilterDataFromRaqbData(immutableTree);
        console.log('onChangeRaqb: elementalFilterData', filterData);
        // with our custom components, the changes may not be finished ..
        if( filterData ) {
            console.log('onChangeRaqb: Write to elementalFilterData');
            setElementalFilterData(filterData);
        }
        else {
            console.log('onChangeRaqb: skip setElementalFilterData');
        }
        console.log('onChangeRaqb END');
    }, []);

    // bubble up the changes to the parent
    useEffect( () => {
        // TODO: only bubble up if the tree can be built successfully
        // console.log('QueryBuilder: sending onChange to parent', elementalFilterData, raqbJsonTree);
        if(onChange) {
            onChange(elementalFilterData, raqbJsonTree);
        }
        else {
            // console.log('no onChange handler provided');
        }
    }, [elementalFilterData,raqbJsonTree]);

    // check the filter validity when the elementalFilterData changes
    // Turn off for now
    useEffect( () => {
        console.log('QueryBuilder: elementalFilterData changed', elementalFilterData);
        if(elementalFilterData) {
            // TODO: need refinement here, this fires aggressively - for example when typing a search value, it triggers onKeyUp so its
            //  sending to the backend every time - which is untenable for performance in real world queries.
            //  Need to implement an api method that validates the FilterData for completeness and leave any actual query execution to the
            //  explicit user action
            // filterCountMutation.mutate(elementalFilterData);
            // filterValidateMutation.mutate(elementalFilterData);
            // runProfilesMutation.mutate(elementalFilterData);
        }
        else{
            // setData(0);
        }
    }, [elementalFilterData]);

    // console.log(filteredData, queryCount);
    // const queryBuilderConfig = getQueryBuilderConfig( { profileProps: profilePropsQuery.data } );
    // console.log('queryBuilderConfig', queryBuilderConfig);
    // console.log('raqbImmutableTree', raqbImmutableTree);

    return (
        <Box py={0} m={0}>
            <Query
                {...queryBuilderConfig}
                value={raqbImmutableTree}
                renderBuilder={renderBuilder}
                onChange={onChangeRaqb}
            />
        </Box>
    );
};

// Builds the config
const QueryBuilder = ( { initialElementalFilterData, initialRaqbJsonTree, onChange, onValid } ) => {

    const [queryBuilderConfig, setQueryBuilderConfig] = useState(null);

    const profilePropsQuery = useQuery({
        queryKey: ['profile_props'],
        queryFn: async () => {
            return await http.get(`${apiV1OrgUri}/profile/properties`);
        },
        onSuccess: (data) => {
            const config = getQueryBuilderConfig({profileProps: data});
            console.log('profilePropsQuery success data', data);
            setQueryBuilderConfig(config);
        }
    }, []
    );

    if (!profilePropsQuery.isSuccess) {
        return <div>Loading... ( or error ) </div>;
    }
    if (!queryBuilderConfig) {
        return <div>Waiting on config</div>;
    }

    console.log('QueryBuilder: initialElementalFilterData', initialElementalFilterData);
    console.log('QueryBuilder: initialRaqbJsonTree', initialRaqbJsonTree);
    console.log('QueryBuilder: queryBuilderConfig', queryBuilderConfig);


    return (
        <QueryBuilderWConfig
            initialElementalFilterData={initialElementalFilterData}
            initialRaqbJsonTree={initialRaqbJsonTree}
            onChange={onChange}
            queryBuilderConfig={queryBuilderConfig}
        />
    );

};



export default QueryBuilder;
