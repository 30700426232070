import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Box, Button, List, ListItem, TextField, Typography} from '@mui/material';
import {IconButton} from '@mui/material';
import {CloseTwoTone} from '@mui/icons-material';
import {useMutation, useQuery} from '@tanstack/react-query';
import * as elemental_axios_client from '../../../util/elemental_axios_client';
import IssuesList from 'components/IssuesList';
import {enqueueSnackbar} from 'notistack';
import {AudienceSelector} from '../Audiences';
import MutationButton from 'components/MutationButton';

const SendTestSidebar = ({ message }) => {

    const [emails, setEmails] = useState([]);
    const [currentEmail, setCurrentEmail] = useState('');
    const [error, setError] = useState('');
    const [successfulEmails, setSuccessfulEmails] = useState([]);

    const [selectedAudiences, setSelectedAudiences] = useState([]);

    const { data: allAudiences, isLoading: isLoadingAudiences , isSuccess: isSuccessAudiences } = useQuery({
        queryKey: ['audiences'],
        queryFn: () => {
            return elemental_axios_client.get('/audiences?per_page=1000');
        },
        select: (data) => {
            return data['audiences'];
        }
    });


    const testAudiences = allAudiences?.filter(audience => audience.is_test);

    const addEmail = () => {
        if(/\S+@\S+\.\S+/.test(currentEmail)) {
            if (emails.includes(currentEmail)) {
                setError('Email already added');
                return;
            }
            setEmails(emailAddresses => [...emailAddresses, currentEmail]);
            setCurrentEmail('');
            setError('');
            return;
        }
        setError('Not a valid email address');
    };

    const removeEmail = (emailToRemove) => {
        setEmails(emailAddresses => emailAddresses.filter((email) => emailToRemove !== email));
    };

    const sendTestMutation = useMutation( {
        mutationFn: ( data) => {
            return elemental_axios_client.post(`/messages/variants/${message.editable_variants[0].id}/send_test`, data);
        },
        onSuccess: (data) => {
            enqueueSnackbar('Test sent', { variant: 'success' });
            // TODO: fill the "successful emails list" for feedback - will identify which recipients got skipped for being suppressed etc
        },
        onError: (error, variables, context) => {
            console.log(error.response.data);
            enqueueSnackbar('Problem sending test', { variant: 'error' });
        }
    });

    const sendTest = async () => {
        const payload = {
            segment_ids: selectedAudiences.map(audience => audience.id),
            emails: emails
        };

        if (payload.segment_ids.length === 0 && payload.emails.length === 0) {
            setError('No recipients selected');
            return;
        }

        sendTestMutation.mutate(payload);
    };

    // TODO: useful for reloading the inputs the next time w/o retyping
    // const saveTestDataToStorage = () => {
    //     localStorage.setItem(`sendTest-${message.id}`, JSON.stringify(currentTestData.current));
    // };
    //
    // const parseDataFromStorage = () => {
    //     const testState = JSON.parse(localStorage.getItem(`sendTest-${message.id}`));
    //     if (testState) {
    //         setEmails(testState.emails);
    //         setPreSelectedRowIds(testState.selectedRowIds);
    //     }
    // };


    return (
        <Box className='flow-sidebar'>
            <Box className='flow-sidebar__body'>
                <Box className='sidebar-send-test'>

                    {sendTestMutation.isSuccess &&
                        <Box sx={{
                            marginTop: '20px',
                            maxWidth: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            {sendTestMutation.data.emails.length > 0 &&
                                <>
                                    <Typography sx={{color: '#49AF41'}} component='div' variant='subtitle1'>Successfully Initiated Test To ..</Typography>
                                    <List>
                                        {sendTestMutation.data.emails.sort((a, b) => a.localeCompare(b)).map(email => <ListItem>{email}</ListItem>)}
                                    </List>
                                </>
                            }
                            {sendTestMutation.data.emails_suppressed.length > 0 &&
                                <>
                                    <Typography sx={{color: 'red'}} component='div' variant='subtitle1'>Ignored suppressed emails:</Typography>
                                    <List>
                                        {sendTestMutation.data.emails_suppressed.sort((a, b) => a.localeCompare(b)).map(email => <ListItem>{email}</ListItem>)}
                                    </List>
                                </>
                            }


                        </Box>
                    }





                    <Typography component='div' variant='subtitle2'>Audiences</Typography>

                    {testAudiences && <AudienceSelector audiences={testAudiences} onChange={setSelectedAudiences} value={[]} /> }

                    <Typography component='div' variant='subtitle2' mt={2}>Emails</Typography>
                    {error && <Typography sx={{color: '#870000'}} component='div' variant='subtitle2'>{error}</Typography>}
                    <Box sx={{ marginLeft: 1 }}>
                        {emails.map((email) => (
                            <Box key={email} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, width: 300}}>
                                <Typography component='div' variant='subtitle1'>{email}</Typography>
                                <IconButton size='small' variant='contained' onClick={() => removeEmail(email)}>
                                    <CloseTwoTone />
                                </IconButton>
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{ marginTop: 1, marginBottom: 2, display: 'flex'
                        //, justifyContent: 'center', alignItems: 'center'
                    }}>
                        <TextField sx={{width: 300}} size='small' value={currentEmail} onChange={(e) => setCurrentEmail(e.target.value)} label='Email address' />
                        <Button className='new_primary_button' disabled={false} onClick={addEmail} style={{width: 'auto', height: '40px', marginLeft: 'auto' }}>
                            Add Email
                        </Button>
                    </Box>

                    {sendTestMutation.isError &&

                        <Box sx={{
                            marginTop: 'auto',
                            maxWidth: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            Problem Sending Test ..
                            <IssuesList issues={sendTestMutation.error.response.data.issue_codes_and_readable} />
                        </Box>
                    }
                    <Box sx={{ marginTop: 'auto', maxWidth: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                        <MutationButton
                            mutation={sendTestMutation}
                            sx={{width: '160px', height: '45px', fontSize: 18}} variant='contained' onClick={sendTest}>
                            Send Test
                        </MutationButton>
                    </Box>


                </Box>
            </Box>
        </Box>
    );
};

export default SendTestSidebar;
