import React from 'react';
import {useParams} from 'react-router-dom';
import {Box, Divider, Typography} from '@mui/material';
import {useMutation, useQueryClient} from '@tanstack/react-query';

import {useJourneyStep, useJourneyStepStats} from 'api/Journeys/journey_queries';
import * as http from 'util/elemental_axios_client';
import {apiV1OrgUri} from 'constants/apiRoutes';
import StatsJourneyStepMessageEmail from 'pages/Journeys/components/Sidebar/Stats/StatsJourneyStepMessageEmail';
import MutationButton from 'components/MutationButton';
import VariantStrategyOverview from 'pages/Journeys/components/Sidebar/MessageEmail/VariantStrategyOverview';
import VariantStrategyEdit from 'pages/Journeys/components/Sidebar/MessageEmail/VariantStrategyEdit';
import {useHistory} from 'react-router';


// TODOS:
// incorporate mutation lifecycle into button MutationButton so clicking goes to spinner while processing
// Editing message ( leave journey ) should close sidebar
// Editign journey message .. clicking "back to journey" in the message edit should:
// 1) come back to the journey in edit mode
// 2) open the sidebar for the message step

// Canvas ..
// Whenever not in edit mode .. clicking a node should not bring up its edit windlw.
// Message node in the canvas "active" needs to render proper message details. Right now it shows the latest edited message details, not the
//  the variant that
// StepHeader / StepBody in the nodes molecules should be decoupled from email or other type specific logic.




const MessageSidebar = ({ stepId, hideSidebar }) => {

    const queryClient = useQueryClient();
    const history = useHistory();

    const { id } = useParams();

    const { data: flow_step, error, isError, isLoading, isSuccess } = useJourneyStep(id, stepId,
        {
            onSuccess: (fetched_flow_step) => {
                console.log(fetched_flow_step);
            }
        });

    const { data: step_stats, isSuccess: step_stats_is_success } = useJourneyStepStats(id, stepId);

    const baseMutationUrl = `${apiV1OrgUri}/flows/${id}/steps/message/${stepId}/strategy/draft`;

    const setVariantStrategyDraftMutation = useMutation(
        async ({ id, stepId }) => {
            return await http.post(baseMutationUrl);
        }, {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['journey_step', stepId]);
            }
        });

    const deleteVariantStrategyDraftMutation = useMutation(
        async ({ id, stepId }) => {
            return await http.remove(baseMutationUrl);
        }, {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['journey_step', stepId]);
            }
        });

    const activateVariantStrategyDraftMutation = useMutation(
        async ({ id, stepId }) => {
            return await http.post(`${baseMutationUrl}/activate`);
        }, {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['journey_step', stepId]);
            }
        });

    const onEditActiveMessage = () => {
        hideSidebar();
        history.push(`/journeys/${id}/message/${stepId}/headers`);
    };

    const createNewDraftStrategy = () => {
        setVariantStrategyDraftMutation.mutate({ id, stepId });
    };

    const deleteDraftStrategy = () => {
        deleteVariantStrategyDraftMutation.mutate({ id, stepId });
    };

    // TODO: do these handlers need to be async if the mutation is async?
    const activateDraftVariantStrategy = async () => {
        activateVariantStrategyDraftMutation.mutate({ id, stepId });
    };

    const wrappedSidebar =
        flow_step &&
            <>
                {/* <SidebarGroup
                    open={Boolean(expanded === 'bypass')}
                    icon={<CallSplitRounded style={{marginRight: 10}}/>}
                    title={flow_step.label_bypass_filter ? flow_step.label_bypass_filter : 'To all customers'}
                    CTA={<Button variant='outlined' onClick={() => setExpanded('bypass')}>{flow_step.bypass_filter_draft || flow_step.bypass_filter ? 'Edit' : 'Setup Filter'}</Button>}
                >
                    {flow_step.label_bypass_filter > 0 ? <Typography align='center' variant='body2'>Excluding those that match {flow_step.label_bypass_filter} conditions</Typography> : null}
                    <Box sx={{ marginTop: 1.5}}>
                        <FlowBypassFilter flowId={id} stepId={stepId} setShowFilter={() => setExpanded(false)} style={{padding: '15px 0 0 0'}} />
                    </Box>
                </SidebarGroup> */}

                {/** Current implementation assumes only variant_strategy of distribution with a single shared variant. This means:*/}
                {/** editing is done on the message*/}
                {/** the variant is the first and only allocation in allocations and allocations_draft ( on an active strategy )*/}

                {flow_step.variant_strategy_draft &&
                    <>
                        Variant strategy draft
                        <VariantStrategyEdit contentContainer={flow_step.message} isEditable={true} onEditActiveMessage={onEditActiveMessage}/>
                        <Box>
                            {flow_step.variant_strategy && <MutationButton onClick={deleteDraftStrategy} mutation={deleteVariantStrategyDraftMutation}>Discard changes</MutationButton> }
                            {flow_step.variant_strategy_draft?.can_activate && flow_step.status === 'active' &&
                                <MutationButton onClick={activateDraftVariantStrategy} mutation={activateVariantStrategyDraftMutation}>Activate changes</MutationButton>
                            }
                            {flow_step.variant_strategy_draft?.can_activate && flow_step.status !== 'active' &&
                                <Box>CAN ACTIVATE MESSAGE ( will happen when step is first activated ) [ needs better indicator ]</Box>
                            }
                        </Box>
                    </>
                }

                {flow_step.variant_strategy !== null &&
                    <>
                        {flow_step.variant_strategy_draft == null &&
                        <MutationButton onClick={createNewDraftStrategy} mutation={setVariantStrategyDraftMutation}>Edit new version</MutationButton>
                    }
                        <Divider/>
                        Active Message
                        <Divider/>
                        <VariantStrategyOverview variantStrategy={flow_step.variant_strategy}/>
                        <Divider/>
                        Stats
                        {step_stats_is_success &&
                            <StatsJourneyStepMessageEmail stats={step_stats} flow_step={flow_step}/>
                        }
                    </>

                }
                {flow_step.variant_strategy_history.length > 0 &&
                    <Box>
                        <Typography >Message History</Typography>
                        {flow_step.variant_strategy_history.map( (variant_strategy) => (
                            <VariantStrategyOverview key={variant_strategy.id} variantStrategy={variant_strategy}/>
                        ))}
                    </Box>
                }

                {/* <SidebarGroup
                    open={Boolean(expanded === 'wait')}
                    icon={<TimelapseTwoTone style={{marginRight: 10}}/>}
                    title={flow_step.label_wait}
                    CTA={<Button variant='outlined' onClick={() => setExpanded('wait')}>{flow_step.label_wait !== 'Process with no wait' ? 'Edit' : 'Setup Delay'}</Button>}
                >
                    <WaitStep hideSidebar={() => setExpanded(false)} stepId={stepId} removeWaitAttribute={removeWaitAttribute} addWaitOnFlowStep={addWaitAttribute} id={id} waitOnStep={flow_step} />
                </SidebarGroup> */}
            </>;

    if ( isLoading ){
        return <Box>Refreshing whole thing</Box>;
    }

    return (
        <Box className='flow-sidebar__body' p={0}>
            <Box className='flow-sidebar__body-content'>
                { isLoading ? (
                    'Loading...'
                ) : isError ? (
                    <span>Error: {error.message}</span>
                ) : (
                    <>
                        {isLoading ? 'Background Updating...' : wrappedSidebar }
                    </>
                )}
            </Box>
        </Box>
    );
};

export default MessageSidebar;
