import React from 'react';
import { Handle } from 'reactflow';
import { ExitNode, MergeExitsNode } from './';

const HANDLE_STYLE = {
    background: 'transparent',
    border: 0
};

const OutputNode = ({ id, data, connectable }) => {

    return (
        <div style={{display: 'flex', flexDirection: 'column', height: 75, margin: 'auto', cursor: 'pointer'}}>
            <Handle
                type='target'
                position='top'
                id='primary'
                style={HANDLE_STYLE}
                onConnect={(params) => console.log('handle onConnect', params)}
            />
            <ExitNode data={data} />
            <MergeExitsNode isConnectable={connectable}  data={data} />
            <Handle
                type='source'
                position='bottom'
                style={HANDLE_STYLE}
                isConnectable={connectable}
            />
        </div>
    );
};

export default OutputNode;
