import React, {useState} from 'react';
import {Box} from '@mui/material';
import {Button, Divider, Typography} from '@mui/material';
import MutationButton from 'components/MutationButton';

// TODO: displayIssues input prop
const EditTemporalAttribute = ({
    ComponentEditDraft,
    componentEditDraftProps = {},
    ComponentDisplayDraft,
    ComponentDisplayActive,
    ComponentDisplayHistory,

    attributeTypeLabel = 'Attribute',
    draft,
    active,
    history = [],
    parentAllowsActivation,

    mutationCreateDraft,
    mutationUpdateDraft,
    mutationDeleteDraft,
    mutationActivateDraft

}) => {

    const [showEditDraft, setShowEditDraft] = useState(  false );

    // TODO: do these handlers need to be async if the mutation is async?
    const createDraft = () => {
        if ( mutationCreateDraft ){
            mutationCreateDraft.mutate();
        }
        else {
            console.log('createDraftMutation not provided');
        }
    };

    // TODO: can we use an update mutation and let the EditDraft component just provide the payload for the mutation?
    // Pros:
    //  - error handling and display can happen in the this component
    // Cons:
    //  - prevents the EditDraft component from custom behavior around save / update.
    // Long term we could provide the option

    const deleteDraft = () => {
        if ( mutationDeleteDraft ){
            mutationDeleteDraft.mutate();
        }
        else {
            console.log('deleteDraftMutation not provided');
        }
    };

    const activateDraft= async () => {
        if ( mutationActivateDraft ){
            mutationActivateDraft.mutate();
        }
        else {
            console.log('activateDraftMutation not provided');
        }
    };

    const handleEditStart = () => {
        setShowEditDraft(true);
    };

    const handleSaveDraft = (mutation_payload) => {
        if (mutationUpdateDraft){
            mutationUpdateDraft.mutate(mutation_payload,
                { onSuccess : (data) => {
                    setShowEditDraft(false);
                }
                });
        }
    };

    // Needed now since the EditComponent manages its own cancel/save events.
    // If we changed to the editComponent having an onSubmit/onCancel set of event handlers,
    // we could use those to set the showEditDraft state
    const handleChangeEditState = (state) => {
        console.log('BranchFilterSidebar handleSwitchEditState', state);
        if(state === 'cancel') {
            setShowEditDraft(false);
        }
        else if(state === 'success') {
            setShowEditDraft(false);
        }
        else if(state === 'editing') {
            setShowEditDraft(true);
        }
    };


    // const handleCancel = () => {
    //     setShowEditDraft(false);
    // }

    // const statusBadge = flowStatesHelper(journeyStep?.status, journeyStep?.has_changes, 'medium');

    const manageActiveDraft =
        <>
            {draft &&
                <>
                    { showEditDraft
                        ? <>
                            <ComponentEditDraft
                                editObj={draft}
                                // TODO: passi in the mutation instead of the handleSaveDraft ?
                                onSave={handleSaveDraft}
                                onChangeEditState={handleChangeEditState}
                                {...componentEditDraftProps} />
                        </>
                        : <Box>
                            <Box>Can activate: { draft.can_activate ? 'yes' : 'no' }</Box>
                            <ComponentDisplayDraft draftObj={draft} />
                            <Button className='new_primary_button' onClick={handleEditStart}>Edit Draft</Button>
                            {active && <MutationButton className='new_primary_button' style={{marginLeft: 'auto'}} onClick={deleteDraft} mutation={mutationDeleteDraft}>Discard draft changes</MutationButton> }
                            {(draft.can_activate && parentAllowsActivation ) &&
                                <MutationButton className='new_primary_button' style={{marginLeft: 'auto'}}  onClick={activateDraft} mutation={mutationActivateDraft}>Activate changes</MutationButton>
                            }
                        </Box>
                    }
                </>
            }
            {active !== null &&
                <>
                    {draft == null &&
                        <MutationButton className='new_primary_button' onClick={createDraft} mutation={mutationCreateDraft}>create new version to edit</MutationButton>
                    }
                    <Divider/>
                    <ComponentDisplayActive activeObj={active} />
                    <Divider/>
                </>
            }
            {history.length > 0 &&
                <Box>
                    <Typography >{attributeTypeLabel} History</Typography>
                    {history.map( (version) => <ComponentDisplayHistory historyObj={version}/>
                    )}
                </Box>
            }

        </>;

    return manageActiveDraft;
};



export default EditTemporalAttribute;
