import React from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Box } from '@mui/material';
import {useJourneyStep} from 'api/Journeys/journey_queries';

import * as http from 'util/elemental_axios_client';
import {apiV1OrgUri} from 'constants/apiRoutes';
import {Button, Divider, Link, Typography} from '@mui/material';
import {BranchReportSidebar} from 'pages/Journeys/components/ReportSidebar';
import {flowStatesHelper} from 'constants/journeysConstants';
import EditTemporalAttribute from 'components/EditTemporalAttribute';
import BranchFilterEdit from 'pages/Journeys/components/Sidebar/BranchFilter/BranchFilterEdit';
import BranchFilterDraft from 'pages/Journeys/components/Sidebar/BranchFilter/BranchFilterDraft';
import BranchFilterActive from 'pages/Journeys/components/Sidebar/BranchFilter/BranchFilterActive';
import BranchFilterHistory from 'pages/Journeys/components/Sidebar/BranchFilter/BranchFilterHistory';



const BranchFilterSidebar = ({stepId, hideSidebar}) => {
    const { id: journeyId } = useParams();
    const queryClient = useQueryClient();

    const { status, data: journeyStep, error, isFetching } = useJourneyStep(journeyId, stepId);

    const statusBadge = flowStatesHelper(journeyStep?.status, journeyStep?.has_changes, 'small');

    const mutationBaseUrl = `${apiV1OrgUri}/flows/${journeyId}/steps/branch_filter/${stepId}/filter/draft`;

    // mutations for temporal_attributes specific to this step
    const createDraftMutation = useMutation(
        async () => {
            return await http.post(`${mutationBaseUrl}`);
        }, {
            onSuccess: (data) => {
                // queryClient.invalidateQueries({ queryKey: ['journey_step', stepId]});
                // queryClient.invalidateQueries({ queryKey: ['journey', journeyId] });
                queryClient.invalidateQueries(['journey', journeyId]);
                queryClient.invalidateQueries(['adjacencyList', journeyId]);
            }
        });

    const deleteDraftMutation = useMutation(
        async () => {
            return await http.remove(`${mutationBaseUrl}`);
        }, {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['journey', journeyId]);
                queryClient.invalidateQueries(['journey_step', stepId]);
                queryClient.invalidateQueries(['adjacencyList', journeyId]);
            }
        });

    const activateDraftMutation = useMutation(
        async () => {
            return await http.post(`${mutationBaseUrl}/activate`);
        }, {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['journey', journeyId]);
                queryClient.invalidateQueries(['journey_step', stepId]);
                queryClient.invalidateQueries(['adjacencyList', journeyId]);
            }
        });

    if(isFetching) {
        return <Box>Loading ...</Box>;
    }

    return (
        <Box className='flow-sidebar__body' p={0}>
            <Box className='flow-sidebar__body-content' display='flex' flexDirection='column'>
                <Box display='flex' justifyContent='space-between' style={{width: '100%', alignItems: 'center'}}>
                    <Typography variant='subtitle1' style={{width: '100%', display: 'inline-flex', alignItems: 'center'}}>Branch On Conditions<span style={{marginLeft: 10}}>{statusBadge}</span></Typography>
                </Box>
                <EditTemporalAttribute
                    attributeTypeLabel={'Conditions'}

                    active={journeyStep.filter}
                    draft={journeyStep.filter_draft}
                    history={journeyStep.filter_history}

                    ComponentEditDraft={BranchFilterEdit}
                    componentEditDraftProps={{
                        journeyId: journeyId,
                        journeyStepId: journeyStep.id
                    }}
                    ComponentDisplayDraft={BranchFilterDraft}
                    ComponentDisplayActive={BranchFilterActive}
                    ComponentDisplayHistory={BranchFilterHistory}

                    parentAllowsActivation={ journeyStep.status === 'active'}

                    mutationCreateDraft={createDraftMutation}
                    mutationDeleteDraft={deleteDraftMutation}
                    mutationActivateDraft={activateDraftMutation}

                />
                {journeyStep.status === 'active' && <BranchReportSidebar stepId={stepId} />}
            </Box>
        </Box>
    );
};


export default BranchFilterSidebar;
