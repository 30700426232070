import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {Alert, Box, Button, Link, Typography} from '@mui/material';
import {FlagRounded} from '@mui/icons-material';
import {useJourney} from 'api/Journeys/journey_queries';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {apiV1OrgUri} from 'constants/apiRoutes';
import * as http from 'util/elemental_axios_client';
import QueryBuilder from 'components/QueryBuilder/QueryBuilder';
import FilterReadable from 'components/FilterReadable';
import MutationButton from 'components/MutationButton';

const DropoutSidebar = ({hideSidebar}) => {
    const { id : journeyId } = useParams();

    const queryClient = useQueryClient();

    const [isEditing, setIsEditing] = useState(false);
    const { data: journey, isSuccess } = useJourney(journeyId);

    const [ elementalFilterData, setElementalFilterData ] = useState();
    const [ raqbJsonTree, setRaqbJsonTree ] = useState();

    const mutationUrl = `${apiV1OrgUri}/flows/${journeyId}/dropout_filter`;

    const mutationSaveDropoutFilter = useMutation(
        {
            mutationFn: async ( payload ) => {
                return await http.put(mutationUrl, payload );
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['journey', journeyId]);
            }
        }
    );

    const mutationDeleteDropoutFilter = useMutation(
        {
            mutationFn: async ( payload ) => {
                return await http.remove(mutationUrl);
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['journey', journeyId]);
            }
        }
    );

    const saveDropoutFilter = async () => {
        const payload = {filter_data: elementalFilterData, raqb_json_tree: raqbJsonTree};
        console.log(payload);
        mutationSaveDropoutFilter.mutate(payload,
            { onSuccess: () => { hideSidebar(); }});
    };

    const handleQueryBuilderChange = (elementalFilterData, raqbJsonTree) => {
        setElementalFilterData(elementalFilterData);
        setRaqbJsonTree(raqbJsonTree);
    };

    const onRemoveFilter = () => {
        mutationDeleteDropoutFilter.mutate();
    };

    if(!isSuccess){
        return <div>Loading or error</div>;
    }

    return (
        <div className='flow-sidebar' style={{width: '15vw'}}>
            <div className='flow-sidebar__header'>
                <Typography component='div' variant='body1' className='flow-sidebar_title no-edit'>
                    <FlagRounded />
                    Person ends the Journey
                </Typography>
            </div>
            {journey.status === 'draft' &&
                <>
                    <Typography component='div' variant='subtitle1' className='flow-sidebar_subtitle' m={1}>
                        when the last step is reached
                    </Typography>
                    <p style={{margin: '0px 24px', color: '#8291AE'}}>- or -</p>
                    { (isEditing || journey.dropout_filter_draft ) ?
                        <>
                            <Box display='flex'>
                                <Typography component='div' variant='subtitle1' className='flow-sidebar_subtitle' m={1}>
                                    when the conditions are met
                                </Typography>
                                {journey.dropout_filter_draft &&
                                    <Link component='button' variant='body2' onClick={onRemoveFilter}>
                                        remove
                                    </Link>
                                }
                            </Box>
                            { mutationSaveDropoutFilter.isError &&
                            <Box mt={4} mr={2} ml={2}>
                                <Alert severity='warning'>Conditions not saved. Please check your conditions again [ TODO: needs specific error ]</Alert>
                            </Box>
                            }
                            <Box className='flow-sidebar__body'>
                                <div  style={{padding: '0px 20px', height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    flexGrow: 1}}>
                                    <Box
                                        className='flow_query_filter create_audience'>

                                        <div className='trigger_exit_filters'>
                                            <div className='branch--filter'>
                                                <QueryBuilder
                                                    onChange={handleQueryBuilderChange}
                                                    initialElementalFilterData={journey.dropout_filter_draft?.filter_data}
                                                    initialRaqbJsonTree={journey.dropout_filter_draft?.raqb_json_tree}
                                                />
                                            </div>
                                        </div>

                                    </Box>
                                    <Box display='flex' justifyContent='space-between' sx={{mx: 1, my: 1}}>
                                            <Button onClick={hideSidebar} className='new_primary_button'
                                                    style={{marginRight: 'auto'}}>
                                                Close
                                            </Button><MutationButton
                                                mutation={mutationSaveDropoutFilter}
                                                onClick={saveDropoutFilter}
                                                className='new_primary_button'
                                                type='submit'
                                                style={{marginLeft: 'auto'}}
                                            >Save
                                            </MutationButton>
                                    </Box>
                                </div>
                            </Box>
                        </>
                        :
                        <Box m={3}>
                            <Button className='new_primary_button' onClick={() => setIsEditing(true)}>Add exit
                                conditions</Button>
                        </Box>}

                </>
            }
            {journey.status === 'active' &&
                <>
                    <Typography component='div' variant='subtitle1' className='flow-sidebar_subtitle' m={1}>
                        when the last step is reached
                    </Typography>
                    {journey.dropout_filter &&
                        <>
                            <p style={{margin: '0px 24px', color: '#8291AE'}}>- or -</p>
                            <Box display='flex'>
                                <Typography component='div' variant='subtitle1' className='flow-sidebar_subtitle' m={1}>
                                    when the conditions are met
                                </Typography>
                            </Box>
                            <Box mr={2} ml={2}>
                                <FilterReadable filterData={journey.dropout_filter.filter_data}/>
                            </Box>
                        </>
                    }
                </>
            }
        </div>
    );
};

export default DropoutSidebar;
