import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from 'contexts/AuthContext';
import Layout from 'components/Templates/Layout';
import SideMenu from 'components/Templates/SideMenu';
import {getOrganizationFromLocalStorage} from './util/helper';
import VerificationPage from './pages/auth/VerificationPage';

const ProtectedRoute = ({ component: Component, componentType: ComponentType, pageName: name, edit, name: subName, ...rest }) => {

    const { currentKratosSession, organizations } = useAuth();

    return (
        <Route
        {...rest}
        render={(props) => {
            if (!currentKratosSession || !currentKratosSession?.active ){
                return <Redirect to={'/login'}/>;
            }

            if (!currentKratosSession?.identity.verifiable_addresses[0].verified) {
                return <VerificationPage />;
            }
            else if (currentKratosSession?.active && organizations?.length > 0) {
                return <Layout main={<Component edit={edit} subName={subName} />} pageName={name} {...props} />;
            }
        }}
    />
    );
};

export default ProtectedRoute;
 