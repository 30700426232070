import {flowStates} from 'constants/journeysConstants';
import {Box, Button, Typography} from '@mui/material';
import {SidebarGroup} from 'components/index';
import {MessageTwoTone} from '@mui/icons-material';
import ContentEmailThumbnailImg from 'components/ContentEmailThumbnailImg';
import React from 'react';

const VariantStrategyEdit = ({ contentContainer, isEditable = false, onEditActiveMessage  }) => {

    const title = (state) => contentContainer.subject ? <>{contentContainer.subject} {flowStates[state]}</> : 'Message setup';

    // can edit a message .. or a sendable variant, right now it just checks ifs its a message
    const isVariant = contentContainer.message_id !== null ? true : false;

    // Right now can only edit a non variant ( ie: message ) that has a draft strategy.
    // Creating the draft strategy happens with a different control than just "Edit"
    const editCTA = <Button variant='outlined' onClick={onEditActiveMessage}>Edit Email</Button>;
    const state = isEditable ? 'draft' : 'active';

    const overview = ( <SidebarGroup
        open={true}
        icon={<MessageTwoTone style={{marginRight: 10}}/>}
        title={title(state)}
        CTA={ isEditable ? editCTA : <></> }
    >

        {contentContainer.from_email && contentContainer.subject ?
            <Box style={{ marginTop: 20, width: '100%', maxHeight: '35rem', height: 'max-content', overflow: 'auto', textAlign: 'center'}} >
                <ContentEmailThumbnailImg emailContent={contentContainer} />
            </Box>
            : <Typography variant='body2' style={{textAlign: 'center'}}>Message not yet configured</Typography>
        }
    </SidebarGroup> );
    return overview;
};

export default VariantStrategyEdit;
