import axios from 'axios';
import {detectIE} from './util';
import { useHistory } from 'react-router-dom';  // Assuming you're using `react-router-dom` for navigation

/** Prepare header as layer of control */
export const getHeaders = (method) => {
    /** Append header */
    const headers = {
        //Authorization: 'Bearer <<access_token>>'
        //Authorization: `f"ApiKey-v1 ${process.env.REACT_APP_AUTH_KEY}`
    };

    if (method === 'post' || method === 'put')
        headers['Content-Type'] = 'application/json';

    /** Deal with IE aggressive caching
    http://stackoverflow.com/questions/2848945/prevent-ie-caching */
    if (detectIE() && method === 'get') {
        headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
        headers['Pragma'] = 'no-cache';
        headers['Expires'] = '0';
    }

    return headers;
};


const AUTH_ERRORS = ['TOKEN_INVALID', 'AUTH_HEADER_MISSING', 'AUTH_HEADER_INVALID_FORMAT'];

// Create an Axios instance if you need. Otherwise, you can attach the interceptor to the default instance.
// const instance = axios.create();

// Use Axios's interceptor to detect errors.
axios.interceptors.response.use(
    response => {
        return response;  // if everything's good, just pass the response to the next promise chain.
    },
    error => {
        console.log('axios.interceptors.response error:', error);
        console.log('error.response:', error.response);
        console.log('error.response.data:', error.response.data);

        if (error.response && error.response.data && AUTH_ERRORS.includes(error.response.data.error?.code)) {
            // If you're using React with `react-router-dom`, you'll need a way to access the history outside of a component.
            // One common approach is to create a history object manually and use it in the <Router>.
            // If you've done that, you can import that history object here and use it to push to the login route:
            // history.push('/login');
            // Another common approach, if this code is inside a React component, is to use the `useHistory` hook:
            localStorage.clear();
            console.log('API intercept code for login:', error.response.data.code);
            // TODO: should show a "session expired" message .. but can't use hooks inside this utility method
            window.location = '/login';
        } else {
            return Promise.reject(error);  // If it's not an auth error, just reject the promise with the error so the calling code can handle it.
        }
    }
);



/** Wrap every request for additional layer of control */
export const request = (opts, responseSelector) => {
    opts.method = opts.method || 'get';
    opts.headers = getHeaders(opts.method);
    opts.withCredentials = true;
    opts.credentials = 'include';

    return axios(opts)
        .then(res => {
            //console.log('HTTP ', `${opts.method.toUpperCase()} ${opts.url}: `, res);
            return responseSelector(res);
        })
        .catch(res => {
            let err = null;
            const response = res.response;
            if (response && response.data) {
                err = response.data;
            } else if (response) {
                err = new Error(response.statusText);
                err.status = response.status;
            } else {
                err = new Error(res || 'HTTP Error');
                err.status = 0;
            }
            console.log('HTTP ', `${opts.method} ${opts.url}: `, err, res);

            throw res;
        });
};

const dataResponseSelector = res => res.data;

/** Export wrapped RESTful methods */
export const get = (url, params) => request({url, params}, dataResponseSelector);
export const post = (url, data) => request({method: 'post', url, data}, dataResponseSelector);
export const put = (url, data) => request({method: 'put', url, data}, dataResponseSelector);
export const patch = (url, data) => request({method: 'patch', url, data}, dataResponseSelector);
// delete is a reserved word? so its remove instead
export const remove = (url, data) => request({method: 'delete', url, data}, dataResponseSelector);
export const delete_ = (url, data) => request({method: 'delete', url, data}, dataResponseSelector);