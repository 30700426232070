import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import {
    LocalAtmTwoTone,
    CallSplitRounded,
    MessageTwoTone,
    TimelapseTwoTone,
    ExpandMore,
    ExpandLess
} from '@mui/icons-material';
import {SidebarGroup} from 'components/index';

const StatsJourneyStepMessageEmail = ({ stats, flow_step }) => {
    const [ openMessage, setOpenMessage ] = React.useState(false);
    const totalExited = stats?.global_dropout_filter.skipped + stats?.bypass_filter.skipped;

    return (
        <>
            <SidebarGroup
                open={openMessage}
                icon={<MessageTwoTone style={{marginRight: 10}}/>}
                title='Message Stats'
                CTA={
                    <IconButton size='small' style={{padding: 5}} onClick={() => setOpenMessage((state) => !state)}>
                        {openMessage ? <ExpandLess style={{margin: 0}}/> : <ExpandMore style={{margin: 0}}/>}
                    </IconButton>
                }
            >
                <Typography component='div' variant='body1'>Sends: <span style={{fontWeight: 400}}>{stats.message?.sends_total}</span></Typography>
                <Typography component='div' variant='body1'>Deliveries: <span style={{fontWeight: 400}}>{stats.message?.deliveries_total} {stats.message?.deliveries_percentage}</span>%</Typography>
                <Typography component='div' variant='body1'>Opens: <span style={{fontWeight: 400}}>{stats.message?.opens_total} {stats.message?.opens_percentage}</span>%</Typography>
                <Typography component='div' variant='body1'>Opens: <span style={{fontWeight: 400}}>{stats.message?.clicks_total} {stats.message?.clicks_percetnage}</span>%</Typography>
                <Typography component='div' variant='body1'>Bounces: <span style={{fontWeight: 400}}>{stats.message?.bounces_total} {stats.message?.bounces_percentage}</span>%</Typography>
                {stats.message?.unsubscribes_total && <Typography component='div' variant='body1'>Unsubscribes: <span style={{fontWeight: 400}}>{stats.message?.unsubscribes_total}</span>%</Typography>}
                <Typography component='div' variant='body1'>Complaints: <span style={{fontWeight: 400}}>{stats.message?.complaints_total} {stats.message?.complaints_percentage}</span>%</Typography>
            </SidebarGroup>
            <SidebarGroup
                open={true}
                icon={<LocalAtmTwoTone style={{marginRight: 10}}/>}
                title='Conversions'
            >
                <Typography component='div' variant='body1'>Revenue: <span style={{fontWeight: 400}}>{stats.revenue_total}</span></Typography>
                <Typography component='div' variant='body1'>Orders: <span style={{fontWeight: 400}}>{stats.orders_total}</span></Typography>
            </SidebarGroup>
            { flow_step.bypass_filter &&
                <SidebarGroup
                    open={true}
                    icon={<CallSplitRounded style={{marginRight: 10}}/>}
                    title='Bypass Filter'
                >
                    <Typography component='div' variant='body1'>Skipped: <span
                        style={{fontWeight: 400}}>{totalExited}</span></Typography>
                </SidebarGroup>
            }
            {flow_step.wait &&
                <SidebarGroup
                    open={true}
                    icon={<TimelapseTwoTone style={{marginRight: 10}}/>}
                    title='Waiting'
                >
                    <Typography component='div' variant='body1'>Waiting: <span
                        style={{fontWeight: 400}}>{stats.wait.waiting}</span></Typography>
                </SidebarGroup>
            }
        </>
    );
};

export default StatsJourneyStepMessageEmail;