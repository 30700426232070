import React from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import {Box, Button, Link, Typography} from '@mui/material';
import { useJourneyStep } from 'api/Journeys/journey_queries';
import {WaitReportSidebar} from 'pages/Journeys/components/ReportSidebar';
import * as http from 'util/elemental_axios_client';
import EditTemporalAttribute from 'components/EditTemporalAttribute';
import WaitEdit from 'pages/Journeys/components/Sidebar/Wait/WaitEdit';
import WaitDraft from 'pages/Journeys/components/Sidebar/Wait/WaitDraft';
import WaitActive from 'pages/Journeys/components/Sidebar/Wait/WaitActive';
import WaitHistory from 'pages/Journeys/components/Sidebar/Wait/WaitHistory';
import {flowStatesHelper} from 'constants/journeysConstants';

const WaitSidebar = ({ stepId : journeyStepId, hideSidebar }) => {
    const { id : journeyId } = useParams();

    const queryClient = useQueryClient();

    const { status, data: journeyStep, error, isFetching } = useJourneyStep(journeyId, journeyStepId);

    const mutationBaseUrl = `/flows/${journeyId}/steps/common/${journeyStepId}/wait/draft`;

    console.log('WaitSidebar', journeyStep);

    const createDraftMutation = useMutation(
        async () => {
            return await http.post(`${mutationBaseUrl}`);
        }, {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['journey_step', journeyStepId]);
            }
        });

    const updateDraftMutation = useMutation(
        async (payload) => {
            return await http.put(`${mutationBaseUrl}`, payload);
        }, {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['journey_step', journeyStepId]);
            }
        });

    const deleteDraftMutation = useMutation(
        async () => {
            return await http.remove(`${mutationBaseUrl}`);
        }, {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['journey_step', journeyStepId]);
            }
        });

    const activateDraftMutation = useMutation(
        async () => {
            return await http.post(`${mutationBaseUrl}/activate`);
        }, {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['journey_step', journeyStepId]);
            }
        });

    if( isFetching ){
        return <Box>Loading ...</Box>;
    }

    const statusBadge = flowStatesHelper(journeyStep.status, journeyStep.has_changes, 'small');

    return (
        <Box className='flow-sidebar__body' p={0}>
            <Box className='flow-sidebar__body-content'>
                <Box display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='column' p={1} className='wait__step'>
                        <Box display='flex' justifyContent='space-between' style={{width: '100%', alignItems: 'center'}}>
                            <Typography variant='subtitle1' style={{display: 'inline-flex', alignItems: 'center'}}>Wait <span style={{marginLeft: 10}}>{statusBadge}</span></Typography>
                        </Box>
                        <EditTemporalAttribute
                            attributeTypeLabel={'Wait'}

                            active={journeyStep.wait}
                            draft={journeyStep.wait_draft}
                            history={journeyStep.wait_history}

                            ComponentEditDraft={WaitEdit}
                            componentEditDraftProps={{
                                journeyId: journeyId,
                                journeyStepId: journeyStep.id
                            }}
                            ComponentDisplayDraft={WaitDraft}
                            ComponentDisplayActive={WaitActive}
                            ComponentDisplayHistory={WaitHistory}

                            parentAllowsActivation={ journeyStep.status === 'active'}

                            mutationCreateDraft={createDraftMutation}
                            mutationUpdateDraft={updateDraftMutation}
                            mutationDeleteDraft={deleteDraftMutation}
                            mutationActivateDraft={activateDraftMutation}

                        />
                        {journeyStep.status === 'active' && <WaitReportSidebar stepId={journeyStepId} />}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default WaitSidebar;
