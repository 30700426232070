/* eslint-disable react/display-name */
import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { Handle, Position } from 'reactflow';
import StepHeader from '../StepHeader';
import { Typography  } from '@mui/material';
import { useJourneyStep } from 'api/Journeys/journey_queries';
import  useLocalStore  from '../../../../useLocalStore';

const HANDLE_STYLE = {
    height: 1,
    width: 1,
    position: 'absolute',
    background: 'transparent', //'#E6F5FD',
    boxSizing: 'border-box',
    border: '2px solid transparent',
    bottom: '0px'
};

const BranchNode = memo(({ data, isConnectable }) => {
    const { id } = useParams();
    const { isDisabled, stepId } = data;

    const { data: step } = useJourneyStep(id, stepId);

    const [setSidebar] = useLocalStore((state) => [state.setSidebar]);


    const handleSidebar = (event) => {
        if(event.target.id !== 'campaign-subject') {
            const payload = {
                open: true,
                name: 'Journeys',
                params: {
                    type: step.type,
                    stepId: data.stepId
                    // activeTab: payload.activeTab
                }
            };

            setSidebar(payload);
        }
    };

    return (
        step ?
            <div className={!isDisabled ? 'flow-branch-node' : 'flow-branch-node disabled'}>
                <Handle
                    type='target'
                    position={Position.Top}
                    style={{ left: '50%', height: 0, border: 0, background: 'transparent' }}
                    isConnectable={isConnectable}
                />

                <StepHeader step={step}  handleSidebar={handleSidebar}>
                    <Typography variant='caption' component='div' style={{fontWeight: 400, marginLeft: 7, fontSize: 12, marginRight: 5}}>
                        {step?.filter_draft?.descriptive_label ?? step?.filter?.descriptive_label ?? step?.description}
                    </Typography>
                </StepHeader>

                <Handle
                    type='source'
                    position={Position.Bottom}
                    id='match'
                    style={{...HANDLE_STYLE, left: '38%'}}
                    isConnectable={isConnectable}
                />
                <Handle
                    type='source'
                    position={Position.Bottom}
                    id='no_match'
                    style={{...HANDLE_STYLE, left: '60%'}}
                    isConnectable={isConnectable}
                />
            </div>
            : <></>
    );
});

export default BranchNode;
