import React, { useContext, useState, useEffect } from 'react';
import {apiV1OrgUri} from 'constants/apiRoutes';
import {Settings} from 'luxon';
import * as http from '../util/elemental_axios_client';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {useHistory, useLocation} from 'react-router';
import { kratosPublicUrl, apiUrl } from 'constants/apiRoutes';
const AuthContext = React.createContext();

export function createOrganizationSettingsForm (formData, cbSuccess, cbError) {
    return http.post(
        '/settings-name',
        formData,
        cbSuccess ? (response) => cbSuccess(response.data) : null,
        cbError ? (response) => cbError(response.data) : null
    );
}

export function useAuth () {
    return useContext(AuthContext);
}

export function AuthProvider ({ children }) {
    const [currentKratosSession, setCurrentKratosSession] = useState(null);
    const [organizations, setOrganizations] = useState(null);

    // TODO: needs to all move to the org / settings pages that use these
    const [settingsData, setSettingsData] = useState();
    const [requestMessage, setRequestMessage] = useState();
    const [successMessage, setSuccessMessage] = useState(false);

    const history = useHistory();
    const location = useLocation();

    const queryKratosSession = useQuery({
        queryKey: ['kratos-sessions', 'current'],
        queryFn: () => {
            return axios.get(`${kratosPublicUrl}/sessions/whoami`,  { withCredentials: true} )
                .then(response => response.data );
        },
        onError: (error, variables, context) => {
            localStorage.clear();
            setCurrentKratosSession(error.response.data);
        }
    },[]);

    useEffect(() => {
        if ( queryKratosSession.data ){
            console.log('setCurrentKratosSession from react-query', queryKratosSession.data );
            setCurrentKratosSession(queryKratosSession.data);
        }
    },[queryKratosSession.data]);

    useEffect( () => {
        if(currentKratosSession) {
            if (currentKratosSession.error) {
                // not logged in or error or something
                // ideally these aren't event protected routes .. they shoudln't be wrapped with the authProvider
                console.log('pathname', location.pathname);
                // if trying to do any of the auth routes .. we just want to show them .. otherwise redirect to /login
                const restrictedPaths = ['/register', '/verification', '/recovery'];
                if(!restrictedPaths.includes(location.pathname)) {
                    history.push('/login');
                }
            }
            if (currentKratosSession.active) {
                if (!currentKratosSession.identity.verifiable_addresses[0].verified) {
                    history.push('/verification');
                }
                if (organizations?.length < 1) {
                    history.push('/create-organization');
                }
            }
        }
    },[currentKratosSession,organizations]);

    const organizationsQuery = useQuery({
        queryKey: ['organizations'],
        queryFn: () => {
            return axios.get(`${apiUrl}/api/v1/identities/current/organizations`, {
                headers: {
                    Accept: 'application/json'
                },
                withCredentials: true
            }).then( res => res.data );
        },
        enabled: currentKratosSession?.active == true
    },[currentKratosSession]);

    useEffect(() => {
        if( organizationsQuery.data ) {
            const organization_active = (organizationsQuery.data && organizationsQuery.data.length > 0) ? organizationsQuery.data[0] : {};
            const organizationJsonStr = JSON.stringify(organization_active);
            localStorage.setItem('organization', organizationJsonStr);
            setOrganizations(organizationsQuery.data);

            // sets the axios default based on this url
            axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/v1/${organization_active.id}`;
            axios.defaults.withCredentials = true;
            axios.defaults.credentials = 'include';
        }
    },[organizationsQuery.data]);


    //Logout
    const logoutAction = async (history) => {
        await fetch(`${kratosPublicUrl}/self-service/logout/browser`, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.json())
            .then(response => {
                logoutUser(response.logout_url, history);
            });
    };

    const redirectToLoginUrl = async () => {
        localStorage.removeItem('organization');
        history.push('/login');
    };

    const logoutUser = async (logoutURL) => {
        await fetch(logoutURL, {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.ok ? redirectToLoginUrl() : response);
    };


    const sendInviteEmail = async (id) => {
        const data = {
            expires_in: '12h',
            identity_id: id
        };

        await fetch(`${kratosPublicUrl}/recovery/link`, {
            method: 'POST',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
            withCredentials: true,
            credentials: 'include'
        })
            .then(response => response.json());
    };

    const fetchSettingsData = async () => {
        await fetch('/settings', {
            method: 'GET',
            headers: { Accept: 'application/json' },
            credentials: 'include',
            withCredentials: true
        })
            .then(response => response.json())
            .then(response => {
                setSettingsData(response);
                console.log('settings response', response);
                const settingsZone = response?.setting_values?.timezone?.value;
                console.log('SettingsZone',settingsZone);
                localStorage.setItem('default_timezone', JSON.stringify((settingsZone && settingsZone.length > 0) ? settingsZone : 'system'));
                Settings.defaultZone = (settingsZone && settingsZone.length > 0) ? settingsZone : 'system';
                console.log('Luxon default Settings.defaultZone', Settings.defaultZone);
            });
    };

    const postOrganizationSettingsForm = async (data) => {
        try {
            const res = await createOrganizationSettingsForm(data);
            setRequestMessage(res);
            setSuccessMessage(true);
            fetchSettingsData();
            return res;
        } catch(error) {
            setRequestMessage(error);
            console.log(error);
            setSuccessMessage(false);
        }
    };

    const value = {
        currentKratosSession,
        setCurrentKratosSession,
        organizations,
        setOrganizations,
        settingsData,
        requestMessage,
        successMessage,
        logoutAction,
        sendInviteEmail,
        fetchSettingsData,
        postOrganizationSettingsForm,
        setRequestMessage,
        setSuccessMessage
    };

    return (
        <AuthContext.Provider value={value}>
            {currentKratosSession && children}
        </AuthContext.Provider>
    );
}
