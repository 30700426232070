import React, { Suspense, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Drawer, List, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    CloseTwoTone,
    CollectionsBookmarkTwoTone,
    EmailTwoTone,
    GroupsTwoTone,
    ImportContactsTwoTone, SettingsApplicationsTwoTone
} from '@mui/icons-material';
import clsx from 'clsx';
import Sidebar from 'components/Sidebar';
import { UnexpectedErrorDialogProvider } from 'contexts/UnexpectedErrorDialogContext';
import { ConfirmProvider }  from 'material-ui-confirm';
import Logo from 'components/Logo';
import useLocalStore from '../../useLocalStore';
import { ListItemIcon } from '@mui/material';
import leftArrow from 'assets/icons/main/arrow_menu_close.svg';
import rightArrow from 'assets/icons/main/arrow_menu_open.svg';
import SideMenu from 'components/Templates/SideMenu';
import { Sidebar as ReactProSidebar, Menu, MenuItem, SubMenu} from 'react-pro-sidebar';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        minWidth: 80,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(8) + 1,
        [theme.breakpoints.up('sm')]: {
            width: 79
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(4),
        maxWidth: '1480px',
        margin: 'auto'
    },
    content_flex: {
        flexGrow: 1,
        maxWidth: '1400px',
        margin: 'auto'
    }
}));

const fullscreen = [
    'Dashboard',
    'CreateJourney',
    'EditCampaigns'
];

const sidebarsWithCustomWidth = ['SingleAudience', 'CampaignStatistics', 'CreateJourney', 'Dashboard', 'CampaignsAll', 'Audience'];



const Layout = ({ main, pageName }) => {
    const classes = useStyles();

    const [getSidebar, resetSidebar] = useLocalStore((state) => [state.getSidebar, state.resetSidebar]);

    const [showQC, setShowQC] = useState(false);

    const sidebar = getSidebar();

    const toggleQC = () => {
        setShowQC((state) => !state);
    };

    const hideSidebar = async () => {
        resetSidebar();
    };

    const menuItems =                     [
        // { url: '/dashboard', label: 'Delivery Statistics', icon: <AnalyticsTwoTone /> },
        // { url: '/journeys', label: 'Journeys', icon: <AccountTreeTwoTone /> },
        { url: '/campaigns', label: 'Campaigns', icon: <EmailTwoTone /> },
        // { url: '/batches', label: 'Batches', icon: <DynamicFeedTwoTone /> },
        { url: '/audiences', label: 'Audiences', icon: <GroupsTwoTone /> },
        { url: '/import-sessions', label: 'Imports', icon: <ImportContactsTwoTone /> },
        { url: '/library', label: 'Template Library', icon: <CollectionsBookmarkTwoTone /> },
        // { url: '/analytics', label: 'Analytics', icon: <Analytics /> },
        { url: '/settings', label: 'Settings', icon: <SettingsApplicationsTwoTone /> }
    ];

    return (
        <UnexpectedErrorDialogProvider>
        <ConfirmProvider>
        <div className={classes.root}>
            <Drawer
                    variant='permanent'
                    style={{
                        minWidth: 80
                    }}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: showQC,
                        [classes.drawerClose]: !showQC
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: showQC,
                            [classes.drawerClose]: !showQC
                        })
                    }}
                    >
                <div className='layout'>
                    <Logo open={showQC} />
                    {/* <List>
                        <Box display='flex' flexDirection='column' justifyContent='space-between' className='side_menu'>
                            <List>
                                <Tooltip title='Quick Create' placement='right'>
                                    <ListItem className='side_menu--item primary' button onClick={toggleQC}>
                                        <ListItemIcon className='side_menu--icons'><AddBoxTwoTone /></ListItemIcon>
                                        <ListItemText primary='Quick Create' />
                                    </ListItem>
                                </Tooltip>
                            </List>
                        </Box>
                    </List> */}
                    <div onClick={toggleQC} className='layout--arrows'>
                        <ListItemIcon className='layout--arrows-list'>
                            {showQC ? (
                                <img src={leftArrow} alt='left' />
                            ) : (
                                <img src={rightArrow} alt='right' />
                            )}
                        </ListItemIcon>
                    </div>

                    <SideMenu open={true} active={pageName} />
                    <ReactProSidebar collapsed={!showQC} >
                        <Menu>
                            <MenuItem label={'Campaigns'} component={<Link to='/campaigns' />} >Campaigns</MenuItem>
                            <MenuItem label={'Journeys'} component={<Link to='/journeys' />} >Journeys</MenuItem>
                            <SubMenu label={'Profiles'}>
                                <MenuItem>Audiences</MenuItem>
                                <MenuItem>Import</MenuItem>
                            </SubMenu>
                            <SubMenu label={'Setup'}>
                                <MenuItem component={<Link to='/email-sender-profiles' />} >Email Sender</MenuItem>
                                <MenuItem component={<Link to='/email-sending-domains' />} >Sending Domain</MenuItem>
                            </SubMenu>
                            <SubMenu label={'Delivery'}>
                                <MenuItem component={<Link to='/vmta-pools' />} >Send Pools</MenuItem>

                            </SubMenu>
                            <SubMenu label={'Admin'}>
                                <MenuItem component={<Link to='/admin/suppression-lists/optizmo' />} >Optizmo</MenuItem>
                                <MenuItem component={<Link to='/admin/campaigns/current' />} >Upcoming/Active Campaigns</MenuItem>
                            </SubMenu>

                        </Menu>
                    </ReactProSidebar>
                </div>
            </Drawer>
            <main className={`${fullscreen.includes(main.props.subName) ? classes.content_flex : classes.content} ${showQC && 'drawer_open'}`}>
                <Suspense fallback={null}>{main}</Suspense>
            </main>
            <Drawer
                anchor='right'
                open={sidebar?.open ?? false}
                onClose={hideSidebar}
                disableEnforceFocus
                style={{zIndex: 1350}}
            >
                {/*<Box className={`${sidebarsWithCustomWidth.includes(main.props.subName) && 'context__custom-width'} context`}>*/}
                <Box className={'context__custom-width context'}>
                    <IconButton size='small' variant='contained' onClick={hideSidebar} className='context-close'>
                        <CloseTwoTone />
                    </IconButton>
                    <Sidebar page={pageName} hideSidebar={hideSidebar} />
                </Box>
            </Drawer>
        </div>
        </ConfirmProvider>
        </UnexpectedErrorDialogProvider>
    );
};

export default Layout;
