/* eslint-disable react/display-name */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {Handle, Position} from 'reactflow';
import {Typography} from '@mui/material';

const MergeNode = React.memo(({ data, style, isConnectable, draggable = false }) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const onRemove = async (evt, stepId, edge) => {
        evt.stopPropagation();
        const follow_key_from = edge.key_from;
        // await dispatch(removeNode(id, stepId, { follow_key_from }));
        console.log('turn into mutation', 'removeNode = (id, stepId, payload) => http.remove(`${API_FLOWS_ROUTE}/${id}/steps/${stepId}`, payload);');

    };

    const onDrop = ( event ) => {
        event.preventDefault();
        console.log('dropped');
    };

    const onDragOver = ( event ) => {
        event.preventDefault();
        return false;
    };

    return (
        <div className={!data.isDisabled ? 'flow-merged-node' : 'flow-merged-node disabled'}
             onDragOver={onDragOver}
             onDrop={onDrop}
             draggable={false}>
            <Handle
                type='target'
                position={Position.Top}
                style={{ border: 0, height: 0, background: 'transparent'}}
                isConnectable={isConnectable}
            />
            <Handle
                type='source'
                position={Position.Bottom}
                style={{ border: 0, marginTop: -210, height: 0, background: 'transparent'}}
                isConnectable={isConnectable}
            />
            <Typography>Merge Merge</Typography>

        </div>
    );
});

export default MergeNode;
