import * as http from 'util/elemental_axios_client';

export const setCreateJourney = async ({ payload }) => {
    const action = '/flows';
    return await http.post(action, payload);
};

export const setUpdateJourney = async ({ id, payload }) => {
    const action = `/flows/${id}`;
    return await http.put(action, payload);
};

export const setStepWaitAttribute = async ({ id, stepId, payload }) => {
    const action = `/flows/${id}/steps/common/${stepId}/wait`;

    return await http.put(action, payload);
};

export const unsetStepWaitAttribute = async ({ id, stepId }) => {
    const action = `/flows/${id}/steps/common/${stepId}/wait`;

    return await http.remove(action);
};

export const unsetStepFilterAttribute = async ({ id, stepId }) => {
    const action = `/flows/${id}/steps/common/${stepId}/bypass_filter`;

    return await http.remove(action);
};

export const unsetDraftChanges = async ({ id }) => {
    const action = `/flows/${id}/revert`;

    return await http.post(action);
};

export const setJourneyTrigger = async ({id, path, payload}) => {
    const action = `/flows/${id}/trigger/${path}`;

    return await http.put(action, payload);
};

export const setActivateJoruney = async ({id}) => {
    const action = `/flows/${id}/activate`;

    return await http.post(action);
};

export const setAppendStep = async ({ id, stepId, payload, target }) => {
    const action = `/flows/${id}/insert_after/${stepId}`;

    return await http.put(action, payload);
};

export const setMergeExits = async ({ id, stepId, exitId }) => {
    const action = `/flows/${id}/${stepId}/merge/${exitId}`;

    return await http.put(action);
};

export const setFlipBranch = async ({ id, stepId }) => {
    const action = `/flows/${id}/${stepId}/swap`;

    return await http.put(action);
};

export const setStepLabel = async ({ id, stepId, payload }) => {
    const action = `/flows/${id}/steps/common/${stepId}/label`;

    return await http.put(action, payload);
};

export const unsetStep = async ({ id, stepId, payload }) => {
    const action = `/flows/${id}/steps/${stepId}`;

    return await http.remove(action, payload);
};
