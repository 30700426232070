import React, { useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormControl, Button, Typography, Select, MenuItem, OutlinedInput, InputAdornment, Box, TextField, Link } from '@mui/material';
import {makeStyles, withStyles} from '@mui/styles';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import { DAYS } from 'util/constants';
import { TimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import  useLocalStore  from 'useLocalStore';
import MutationButton from 'components/MutationButton';

const StyledToggleButtonGroup = withStyles(theme => ({
    grouped: {
        margin: theme.spacing(2),
        padding: theme.spacing(0, 1),
        '&:not(:first-child)': {
            border: '1px solid',
            borderColor: '#2B437A',
            borderRadius: '50%'
        },
        '&:first-child': {
            border: '1px solid',
            borderColor: '#2B437A',
            borderRadius: '50%'
        }
    }
}))(ToggleButtonGroup);

const StyledToggle = withStyles({
    root: {
        color: '#2B437A',
        '&$selected': {
            color: 'white',
            background: '#2B437A'
        },
        '&:hover': {
            borderColor: '#4C6BAF',
            background: '#4C6BAF'
        },
        '&:hover$selected': {
            borderColor: '#4C6BAF',
            background: '#4C6BAF'
        },
        minWidth: 32,
        maxWidth: 32,
        height: 32,
        textTransform: 'unset',
        fontSize: '0.75rem'
    },
    selected: {}
})(ToggleButton);


const useStyles = makeStyles({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1
    }});

const WaitEdit = ( {editObj: wait, journeyStepId: id, onSave, onChangeEditState }) => {

    const { register, handleSubmit, formState: { errors }, getValues, control, setValue } = useForm();

    const untilFirst = wait.until_time_of_day;
    const untilMax =  wait.until_time_of_day_max;
    const waitOnlyOnDays = wait.only_on_days;

    const [selectionDays, setSelectionDays] = useState(waitOnlyOnDays?.length > 0 ? 'selection' : 'all');
    const [when, setWhen] = useState(untilFirst && untilMax ? 'until_max' : untilFirst ? 'until' : 'now');

    const classes = useStyles();

    const onSubmit = () => {
        const until_time_of_day = getValues('until_time_of_day');
        const until_time_of_day_max = getValues('until_time_of_day_max');
        const days = getValues('days');
        const hours = getValues('hours');
        const minutes = getValues('minutes');
        const is_active = getValues('is_active');
        const only_on_days = getValues('only_on_days');

        const formData = {
            is_active,
            days,
            hours,
            minutes,
            until_time_of_day: until_time_of_day && until_time_of_day.toFormat('HH:mm:ssa'),
            until_time_of_day_max: until_time_of_day_max && until_time_of_day_max.toFormat('HH:mm:ssa'),
            only_on_days
        };

        if(onSave){
            onSave(formData);
        }

    };

    const handleDaySelect = (e) => {
        setSelectionDays(e.target.value);
        e.target.value === 'all' && setValue('only_on_days', []);
    };

    const handleChangeTime = (e) => {
        setWhen(e.target.value);
        if(e.target.value === 'now') {
            setValue('until_time_of_day', null);
            setValue('until_time_of_day_max', null);
        } else if (e.target.value === 'until') {
            setValue('until_time_of_day_max', null);
        }
    };

    const handleCancel = () => {
        if( onChangeEditState ){
            onChangeEditState('cancel');
        }
    };

    return (

        <div className={classes.content} style={{padding: '25px 15px', borderBottom: '1px solid #D5DDED'}}>
            <Box className='flow_query_filter create_audience'>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box display='flex' pt={1} pb={4}>
                        <OutlinedInput {...register('days', {valueAsNumber: true, min: 0})} name='days' defaultValue={wait?.days || 0} type='number' inputProps={{ min: 0 }}  endAdornment={<InputAdornment position='end'>Days</InputAdornment>} />
                        <OutlinedInput {...register('hours', {valueAsNumber: true, min: 0})} name='hours' defaultValue={wait?.hours || 0}  type='number' inputProps={{ min: 0 }} endAdornment={<InputAdornment position='end'>Hours</InputAdornment>} />
                        <OutlinedInput {...register('minutes', {valueAsNumber: true, min: 0})} name='minutes' defaultValue={wait?.minutes || 0} type='number' inputProps={{ min: 0 }} endAdornment={<InputAdornment position='end'>Minutes</InputAdornment>} />
                    </Box>

                    <Typography variant='subtitle1'>Then Proceed</Typography>
                    <Box display='flex' pt={1}>
                        <FormControl variant='outlined' size='small'>
                            <Select MenuProps={{ style: {zIndex: 35001} }} value={when} onChange={handleChangeTime} style={{fontSize: '14px'}} >
                                <MenuItem key={1} value={'now'}>Right away</MenuItem>
                                <MenuItem key={2} value={'until'}>The next time is</MenuItem>
                                <MenuItem key={3} value={'until_max'}>During the next time window</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box display='flex' pb={4}>
                        {(when === 'until' || when === 'until_max') && (
                            <Box display='flex' mr={1} pt={1} style={{zIndex: 3000}}>
                                <Controller
                                    control={control}
                                    name='until_time_of_day'
                                    render={({ field: { onChange, value } }) => (
                                        <TimePicker
                                            style={{zIndex: 3000}}
                                            inputFormat='hh:mm a'
                                            defaultValue={untilFirst ? DateTime.fromISO(untilFirst).toUTC() : null}
                                            value={value ? value : untilFirst ? DateTime.fromISO(untilFirst).toUTC() : null}
                                            onChange={onChange}
                                            renderInput={(params) => <TextField {...params} style={{zIndex: 3000, fontSize: 14}} inputProps={{ ...params.inputProps, placeholder: when === 'until' ? 'until' : 'from' }} size='small' />}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                        {when === 'until_max' && (
                            <Box display='flex' ml={1} pt={1}>
                                <Controller
                                    control={control}
                                    name='until_time_of_day_max'
                                    render={({ field: { onChange, value } }) => (
                                        <TimePicker
                                            style={{zIndex: 3000}}
                                            inputFormat='hh:mm a'
                                            defaultValue={untilMax ? DateTime.fromISO(untilMax).toUTC() : null}
                                            value={value ? value : untilMax ? DateTime.fromISO(untilMax).toUTC() : null}
                                            onChange={onChange}
                                            renderInput={(params) => <TextField {...params}
                                                                                inputProps={{ ...params.inputProps, placeholder: 'until' }} size='small' />}
                                        />
                                    )}
                                />
                            </Box>
                        )}
                    </Box>

                    <Typography variant='subtitle1'>On</Typography>
                    <Box display='inline-flex' justifyContent='space-between' pt={1}>
                        <FormControl variant='outlined' size='small' style={{ alignSelf: 'center', width: 'max-content' }}>
                            <Select MenuProps={{ style: {zIndex: 35001} }} value={selectionDays} onChange={handleDaySelect} style={{fontSize: '14px'}} >
                                <MenuItem key={4} value={'all'}>Any day of the week</MenuItem>
                                <MenuItem key={5} value={'selection'}>Only on days</MenuItem>
                            </Select>
                        </FormControl>
                        {selectionDays === 'selection' &&
                            <Controller
                                control={control}
                                name='only_on_days'
                                onChange={([, data]) => data}
                                render={({ field: { onChange, value } }) => (
                                    <StyledToggleButtonGroup
                                        size='small'
                                        arial-label='Days of the week'
                                        value={value ? value : wait?.only_on_days}
                                        onChange={(event, value) => onChange(value)}
                                        style={{marginLeft: '10px'}}
                                    >
                                        {DAYS.map((day, index) => (
                                            <StyledToggle size='medium' key={day.key} value={index} aria-label={day.key} style={{margin: '3px'}}>
                                                {day.label}
                                            </StyledToggle>
                                        ))}
                                    </StyledToggleButtonGroup>
                                )}
                            />
                        }
                    </Box>
                </form>
            </Box>
            <Box display='flex' justifyContent='space-between' sx={{mx: 1, my: 1}}>
                <Button onClick={handleCancel} className='new_primary_button' style={{marginRight: 'auto'}}>
                    Cancel
                </Button>
                <MutationButton
                    // TODO: need to get the mutation ( which is existing at the EditTemporalAttribute level ) into this component
                    mutation={null}
                    onClick={handleSubmit(onSubmit)}
                    className='new_primary_button'
                    type='submit'
                    style={{marginLeft: 'auto'}}
                >
                    Save
                </MutationButton>
            </Box>
        </div>

    );
};

export default WaitEdit;
